<template>
  <v-col cols="12">
    <crud-filho-list
      :mostra-tabela="!formulario"
      :items="imagens"
      :paginacao.sync="paginacaoImagem"
      :loading="loadingImagem"
      :hide-default-header="true"
      :headers="cabecalhoImagem"
      :total-registros="totalRegistros"
      @novo-event="adicionarImagem"
    >
      <template
        slot="items"
        slot-scope="imagem"
      >
        <tr>
          <td
            class="justify-left btnEditar cursorPointer"
            style="width: 128px"

            @click="visualizaImagem(imagem.item.item)"
          >
            <v-avatar
              :tile="true"
              size="128px"
              color="blue"
            >
              <img
                :src="imagem.item.item.img"
                class="cursorPointer"
              >
            </v-avatar>
          </td>
          <td class="justify-center">
            <v-icon
              color="teal"
              @click="editarImagem(imagem.item.item)"
            >
              mdi mdi-tooltip-edit
            </v-icon>
            <v-list-item-title>
              {{ imagem.item.item.titulo }}
            </v-list-item-title>
            <i>{{ imagem.item.item.comentario }}</i>
          </td>
        </tr>
      </template>
    </crud-filho-list>
    <imagem-upload
      :is-editting="editandoImagem"
      :id-imagem.sync="idImagem"
      :dialog.sync="dialogImagem"
      @imagem-salva="imagemSalva"
      @imagem-removida="imagemRemovida"
    />
  </v-col>
</template>

<script>

  import ImagemService from '@/services/ImagemService'

  export default {
    props: {
      listaImagens: {
        type: Array,
        default: null
      }
    },
    data: () => ({
      alertValidacao: false,
      tituloPaginaTab: null,
      formulario: false,
      cabecalhoImagem: [],
      isEditting: true,
      totalRegistros: 0,

      imagens: [],
      idImagem: null,
      dialogImagem: false,
      editandoImagem: false,
      paginacaoImagem: null,
      loadingImagem: false
    }),

    watch: {
      listaImagens (valor) {
        if (valor && valor.length > 0) {
          this.buscaImagens()
        }
      }
    },
    created() {
      if (this.listaImagens && this.listaImagens.length > 0) {
        this.buscaImagens()
      }
    },
    methods: {
      visualizaImagem (imagem) {
        this.$root.$emit('view-image', imagem.img)
      },
      adicionarImagem () {
        this.editandoImagem = false
        this.idImagem = 0
        this.dialogImagem = true
      },
      editarImagem (imagem) {
        this.editandoImagem = true
        this.idImagem = imagem.id
        this.dialogImagem = true
      },
      buscaImagens () {
        if (this.listaImagens && this.listaImagens.length > 0) {
          ImagemService.findByIds({
            ids: this.listaImagens
          }, {
            rowsPerPage: 100
          })
            .then(imagens => {
              this.processaRetornoConsultaImagens(imagens)
            })
            .catch(error => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Ocorreu um erro ao buscar as imagens desta reclamação:\n ->' + error
              })
            })
            .finally(() => {
              this.loadingImagem = false
              this.dialog = true
            })
        } else {
          this.loadingImagem = false
          this.dialog = true
        }
      },
      imagemSalva (idImagem) {
        this.$emit('imagem-salva', idImagem)
      },
      imagemRemovida (idImagem) {
        this.$emit('imagem-removida', idImagem)
      },
      processaRetornoConsultaImagens (imagensBanco) {
        const imagensRetorno = imagensBanco.data
        this.imagens = []
        for (let i = 0; i < imagensRetorno.length; i++) {
          const imagemProcessar = imagensRetorno[i]
          const buffer = Buffer.from(imagemProcessar.img, 'base64')
          var myBase64 = window.btoa(ImagemService.Uint8ToString(buffer))
          imagemProcessar.img = 'data:image/*;base64,' + myBase64
          this.imagens.push(imagemProcessar)
        }
        this.totalRegistros = this.imagens.length
      }
    }
  }

</script>
